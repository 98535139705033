/* :root { */
    /* --bg-card:#1E2330; 
    --bg-foter:#07090F; 
    --bg-main:#FFFFFF; 
    --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
    --bg-navbar:#0D0F14; 
    --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
    --bg-text-animation:#1E2330; 
    --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
    --bt-danger:#FF0000; 
    --bt-defult:#FFFFFF; 
    --bt-info:#434958; 
    --bt-login:#FFDE8B; 
    --bt-register:#2B00D6; 
    --bt-secondary:#C9C9C9; 
    --bt-sucess:#00ED09; 
    --bt-undefult:#000000; 
    --bt-warning:#FFE000;
    --bg-grey:#2F3642; 
    --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
    --bg-blue:#0038FF; 
    --bg-sea​​blue:#00A3FF;
    --bg-green:#148C00; */

    /* --bg-brand: #D30101;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-line: #C3C4C6;
    --bg-sucess: #00B907;
    --bg-floor: #D5D5D5;
    --bg-warning: #FFE600;
    --bg-card: #FFFFFF;
    --bg-secondary: #E6E8EB;
    --bg-dark-gray: #707070;
    --bg-blue: #001585;
    --bg-grey: #1E2330;
    --bg-danger: #E10000;
}  */
  
.fixedBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--bg-black);
    z-index: 1400;
    opacity: 0.8;
}
  
.contentPosition {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1600;
    display: block;
    /* width: 100%; */
    height: 100%;
    outline: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: opacity 0.15s linear;
    transform: translate(-50%, -50%);
}

.box,
.boxFull {
    border: 2px solid var(--bg-line);
    border-radius: 16px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    outline: 0;
}
  
.boxTitle {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: var(--bg-black);
}
  
.boxBody, 
.boxBodyFull {
    position: relative;
    width: auto;
    margin: 1.5rem;
    pointer-events: none;
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}
  
.boxContent,
.boxContentFull {
    padding: 30px 30px 30px 30px;
    width: 490px;
    height: 100%;
    text-align: left;
    background: var(--bg-card);
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.boxContentError {
    padding: 30px 30px 30px 30px;
    width: 420px;
    height: 100%;
    text-align: left;
    background: var(--bg-white);
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.boxContentFull {
    width: 600px;
}

.boxContentAlert {
    width: 400px;
}

.confirmParagrap {
    font-size: 20px;
    color: var(--bg-black);
    margin: 20px 0px 30px 0px;
}

.boxContentUnset {
    padding: 0px !important;
}

.closeButtonFull {
  position: absolute;
  top: 50%;
  right: 0px;
  z-index: 1600;
}

.closeButton {
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 1600;
}

.iconImgFull {
    position: relative;
    color: white;
    width: 50px !important;
    height: 100% !important;
    cursor: pointer;
}

.closeImg,
.closeImgFull {
    position: relative;
    width: 60px;
    height: 100%;
    cursor: pointer;
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.closeImg:hover,
.iconImgFull:hover {
    top: 2px;
}

.conditionTitle {
    padding: 20px 20px 10px 20px;
    color: var(--bg-white);
    font-size: 18px;
    border-bottom: 1px solid var(--bg-line);
    background: var(--bg-brand);
    border-radius: 10px 10px 0px 0px;
}

.conditionTitlePaddingOther {
    padding: 20px 20px 20px 20px;
}

.conditionMaginTop {
    margin-top: 10px;
}

.conditionHead {
    padding: 20px 20px 10px 20px;
    color: var(--bg-black);
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid var(--bg-dark-gray);
    background: var(--bg-card);
}

.conditionBody {
    padding: 20px;
    background: var(--bg-card);
    font-size: 16px;
    color: var(--bg-black);
    /* background: var(--bg-modal); */
}

.conditionBodyRadius {
    border-radius: 0px 0px 10px 10px;
}

.conditionBodyPadding {
    padding: 0px;
}

.theadSize {
    padding: 16px 20px !important;
}

.tableList td {
    padding: 16px 20px !important;
}

.htmlUpdate {
    margin-top: 20px;
    border-top: solid 1px var(--bg-line);
}

.htmlUpdateList {
    padding: 14px 0px;
    font-size: 16px;
    border-bottom: solid 1px var(--bg-line);
}

@media only screen and (max-width: 600px) {
    .boxBodyFull {
        margin: 0px;
    }
    .boxFull {
        min-height: 100vh;
        border-radius: 0px;
        width: 100%;
        border: 0px;
    }
    .boxContent,
    .boxContentError {
        width: 84vw;
        padding: 30px 15px;
    }
    .boxContentFull {
        width: 100vw;
        min-height: 100vh;
        padding: 40px 20px 40px 20px;
        border-radius: 0px;
    }
    /* .closeButtonFull {
        right: 6px;
        top: 6px;
    } */
    .closeImg {
        width: 50px;  
        height: 50px; 
    }
    .closeImgFull {
        width: 38px;  
        height: 38px;
    }
    .iconImgFull {
        width: 46px !important;
    }
    .conditionTitlePaddingOther {
        font-size: 16px;
        padding: 10px 5px 10px 5px;
    }
    .conditionBodyRadius {
        border-radius: 0px;
    }
    .confirmParagrap {
        font-size: 18px;
        margin: 5px 0px 30px 0px;
    }
    .boxTitle {
        font-size: 22px;
    }
    .conditionTitleMobile {
        padding: 7px 5px 6px 5px;
        color: var(--bg-white);
        font-size: 16px;
        border-bottom: 1px solid var(--bg-line);
        background-color: var(--bg-brand);
    }
    .conditionTitle {
        border-radius: 0px;
    }
    .conditionBodyUnsetBG {
        background: unset;
    }
}
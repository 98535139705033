/* :root { */
/* --bg-card:#1E2330; 
    --bg-foter:#07090F; 
    --bg-main:#FFFFFF; 
    --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
    --bg-navbar:#0D0F14; 
    --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
    --bg-text-animation:#1E2330; 
    --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
    --bt-danger:#FF0000; 
    --bt-defult:#FFFFFF; 
    --bt-info:#434958; 
    --bt-login:#FFDE8B; 
    --bt-register:#2B00D6; 
    --bt-secondary:#C9C9C9; 
    --bt-sucess:#00ED09; 
    --bt-undefult:#000000; 
    --bt-warning:#FFE000;
    --bg-grey:#2F3642; 
    --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
    --bg-blue:#0038FF; 
    --bg-sea​​blue:#00A3FF;
    --bg-green:#148C00; */

/* --bg-brand: #D30101;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-line: #C3C4C6;
    --bg-sucess: #00B907;
    --bg-floor: #D5D5D5;
    --bg-warning: #FFE600;
    --bg-card: #FFFFFF;
    --bg-secondary: #E6E8EB;
    --bg-dark-gray: #707070;
    --bg-blue: #001585;
    --bg-grey: #1E2330;
    --bg-danger: #E10000;
}  */

.tableSize {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    font-size: 16px;
}

.tableConditionSize {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    font-size: 16px;
}

.theadSize {
    font-size: 18px;
    padding: 6px 0px 10px 0px;
    color: var(--bg-dark-gray);
}

.theadConditionSize {
    font-size: 18px;
    padding: 6px 0px 10px 0px;
}

.theadSizeBorder {
    font-size: 18px;
    padding: 6px 0px 10px 0px;
    border-bottom: 1px solid var(--bg-line);
}

.theadSizePopup {
    font-size: 16px !important;
    padding: 16px 15px !important;
}

.tableList {
    padding: 8px 0px;
}

.tableList td {
    padding: 16px 0px;
    font-weight: 600;
}

.tableListPopup td {
    padding: 16px 6px;
    font-size: 15px;
    font-weight: 600;
}

.listGrey {
    background: var(--bg-secondary);
}

.listDefault {
    background: var(--bg-card);
}

.listTdPadding {
    padding-left: 12px !important;
}

.tableIcon {
    position: relative;
    padding: 6px;
    width: 24px !important;
    height: 100% !important;
    color: var(--bg-white);
    border-radius: 6px;
    cursor: pointer;
}

.tableIcon:hover {
    top: 2px;
}

.iconBgRegister {
    background: var(--bg-blue);
}

.iconBgDanger {
    background: var(--bg-danger);
}

.dataNull {
    font-size: 24px;
    margin: 30px 0px;
    color: #BABDC8;
}

.loadMoreAlign {
    padding: 40px 0px 80px 0px;
}

.loadMore {
    display: inline-block;
    padding: 14px 36px;
    font-size: 18px;
    font-weight: 600;
    border: 2px solid var(--bg-black);
    border-radius: 50px;
    position: relative;
    cursor: pointer;
}

.loadMore:hover {
    top: 2px;
}

.listGrid {
    background: var(--bg-card);
    padding: 13px;
    margin-bottom: 6px;
    margin-top: 6px;
    border-radius: 10px;
    border: 1px solid var(--bg-line);
}

.listTitle {
    font-size: 17px;
    color: var(--bg-black);
    font-weight: 700;
}

.listDate {
    font-size: 15px;
    color: var(--bg-dark-gray);
}

.listBtnText {
    font-size: 15px;
    margin-top: -3px;
    font-weight: 700;
}

.listAmount {
    font-size: 17px;
    font-weight: 700;
}

.listAmountNumber {
    color: var(--bg-brand);
}

.listAmountWin {
    color: var(--bg-sucess);
}

.listNumber {
    font-size: 16px;
    font-weight: bold;
    color: var(--bg-brand);
}

.listTextResult {
    font-size: 13px;
    margin-top: 6px;
}

.listTextResultInfo {
    color: var(--bg-dark-gray);
}

.modalBgFloor {
    background: var(--bg-floor);
}

.textDarkGray {
    color: var(--bg-dark-gray);
    font-weight: normal;
}

.bgFloor {
    background: var(--bg-floor);
    padding: 10px;
    margin: 10px 7px 0px 7px;
}

@media only screen and (max-width: 600px) {
    .theadSizePopup {
        font-size: 12px !important;
        padding: 16px 12px !important;
        color: var(--bg-dark-gray);
    }

    .tableSize {
        font-size: 12px;
    }

    .tableList td,
    .tableListPopup td {
        padding: 10px 12px !important;
    }

    .tableIcon {
        width: 15px !important;
        height: 15px !important;
        padding: 4px;
    }

    .loadMoreAlign {
        padding: 10px 0px 40px 0px;
    }

    .loadMore {
        font-size: 14px;
        border: 1px solid var(--bg-white);
    }

    .label {
        padding: 3px 8px;
    }

    .listTdPaddingPopup {
        padding-left: 10px !important;
    }

    .theadConditionSize {
        font-size: 16px;
    }

    .dataNull {
        font-size: 18px;
    }

    .theadSizeBorder {
        font-size: 12px;
    }

    .theadSize {
        font-size: 12px;
    }
    .listGrid {
        padding: 10px;
        margin: 10px 6px;
    }
    .listTitle {
        font-size: 15px;
    }
    .listDate {
        font-size: 13px;
    }
    .listAmount {
        font-size: 14px;
    }
    .listBtnText {
        font-size: 13px;
    }
}
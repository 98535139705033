/* :root { */
    /* --bg-card:#1E2330; 
    --bg-foter:#07090F; 
    --bg-main:#FFFFFF; 
    --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
    --bg-navbar:#0D0F14; 
    --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
    --bg-text-animation:#1E2330; 
    --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
    --bt-danger:#FF0000; 
    --bt-defult:#FFFFFF; 
    --bt-info:#434958; 
    --bt-login:#FFDE8B; 
    --bt-register:#2B00D6; 
    --bt-secondary:#C9C9C9; 
    --bt-sucess:#00ED09; 
    --bt-undefult:#000000; 
    --bt-warning:#FFE000;
    --bg-grey:#2F3642; 
    --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
    --bg-blue:#0038FF; 
    --bg-sea​​blue:#00A3FF;
    --bg-green:#148C00; */

    /* --bg-brand: #D30101;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-line: #C3C4C6;
    --bg-sucess: #00B907;
    --bg-floor: #D5D5D5;
    --bg-warning: #FFE600;
    --bg-card: #FFFFFF;
    --bg-secondary: #E6E8EB;
    --bg-dark-gray: #707070;
    --bg-blue: #001585;
    --bg-grey: #1E2330;
    --bg-danger: #E10000;
}  */

.btnSizeFull {
    width: 100%;
}

.btnSizeMedium {
    width: 50%;
}

.btnOutLine {
    font-size: 18px;
    font-weight: 600;
    padding: 12px;
    border-radius: 10px;
    border: 2px solid var(--bg-line); 
    color: var(--bg-white);
    background: unset;
}

.btnOutLineColor {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    padding: 12px;
    border-radius: 10px;
    cursor: pointer;
    border: 2px solid var(--bg-line); 
    color: var(--bg-black);
    background: var(--bg-card);
}

.btnOutLineColor:hover {
    top: 2px;
}

.outLineSuccess {
    color: var(--bg-sucess) !important;
    border: 2px solid var(--bg-sucess) !important;
}

.outLineInfo {
    color: var(--bg-grey);
    border: 1px solid var(--bg-line);
}

.outLineDanger {
    color: var(--bg-black) !important;
    border: 2px solid var(--bg-line) !important;
}

/* .outLineDanger:hover {
    background: var(--bg-danger);
    border: 1px solid var(--bg-danger); 
} */

.outLineDangerActive {
    background: var(--bg-brand) !important;
    border: 1px solid var(--bg-brand) !important; 
    color: var(--bg-white) !important;
}

.btn {
    position: relative;
    padding: 14px 0px;
    border-radius: 10px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    color: var(--bg-black);
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}

.btnPaddingOther {
    padding: 14px 0px;
}

.btn:hover { 
    top: 2px;
}

.btnSuccess {
    background: var(--bg-sucess);
}

.btnRegister {
    background: var(--bg-blue);
}

.outLineRegister {
    color: var(--bg-blue) !important;
    border: 2px solid var(--bg-blue) !important;
}

.btnDanger {
    background: var(--bg-danger);
}

.outLineBrand {
    color: var(--bg-brand) !important;
    border: 2px solid var(--bg-brand) !important;
}

.btnConfirm {
    border-radius: 5px;
    background: var(--bg-blue);
    color: var(--bg-white);
    font-size: 20px;
    font-weight: bold;
}

.btnInfo {
    background: var(--bg-dark-gray);
}

.btnTypeLotOther {
    background: var(--bg-white);
}

.btnTypeLotOther:hover {
    background: var(--bg-secondary);
}

.btnTypeLotOtherActive {
    background: var(--bg-danger);
    color: var(--bg-white);
}

.btnBorderActive {
    border: 2px solid var(--bg-line);
}

.btnRadius {
    border-radius: 10px !important;
}

.btnCancel {
    border-radius: 5px;
    background: var(--bg-danger);
    color: var(--bg-white);
    font-size: 20px;
    font-weight: bold;
}

.btnLoadMore {
    border-radius: 10px;
    border: 1px solid var(--bg-line);
    background: var(--bg-card);
    font-weight: 600;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    color: var(--bg-black);
    text-align: center;
    cursor: pointer;
}

.btnLoadMore:hover {
    background: var(--bg-line);
}

@media only screen and (max-width: 600px) {
    .btnOutLineColor {
        font-size: 14px;
        padding: 12px 4px;
        border: 1px solid var(--bg-dark-gray);
    }
    .btn {
        font-size: 16px;
    }
    .btnOutLine {
        font-size: 16px;
        padding: 10px;
    }
    .outLineDanger:hover {
        background: unset;
        border: 1px solid var(--bg-dark-gray);
    }
    .outLineDangerActive {
        background: var(--bg-brand);
        border: 1px solid var(--bg-brand) !important; 
    }
    .btnBorderActive {
        padding: 10px 0px;
    }
    .btnPaddingOther {
        padding: 10px 0px;
    }
}

@media only screen and (max-width: 370px) {
    .btnOutLine {
        font-size: 14px;
    }
}
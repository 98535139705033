/* :root { */
    /* --bg-card:#1E2330; 
    --bg-foter:#07090F; 
    --bg-main:#FFFFFF; 
    --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
    --bg-navbar:#0D0F14; 
    --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
    --bg-text-animation:#1E2330; 
    --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
    --bt-danger:#FF0000; 
    --bt-defult:#FFFFFF; 
    --bt-info:#434958; 
    --bt-login:#FFDE8B; 
    --bt-register:#2B00D6; 
    --bt-secondary:#C9C9C9; 
    --bt-sucess:#00ED09; 
    --bt-undefult:#000000; 
    --bt-warning:#FFE000;
    --bg-grey:#2F3642; 
    --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
    --bg-blue:#0038FF; 
    --bg-sea​​blue:#00A3FF;
    --bg-green:#148C00; */

    /* --bg-brand: #D30101;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-line: #C3C4C6;
    --bg-sucess: #00B907;
    --bg-floor: #D5D5D5;
    --bg-warning: #FFE600;
    --bg-card: #FFFFFF;
    --bg-secondary: #E6E8EB;
    --bg-dark-gray: #707070;
    --bg-blue: #001585;
    --bg-grey: #1E2330;
    --bg-danger: #E10000;
}  */

.navbar {
    background: var(--bg-card);
    padding: 6px 0px 3px 0px;
    /* background: #D30101; */
}

.navbarSub {
    background-color: var(--bg-brand);
    color: var(--bg-white);
    font-size: 16px;
}

.navbarSubHeigth {
    height: 48px;
}

.imgLogo {
    width: 180px;
    height: 100%;
}

.creditAlign {
    /* background: var(--bt-undefult); */
    box-sizing: border-box;
    padding: 6px 0px;
    margin-top: 4px;
    margin-bottom: 6px;
    border-radius: 8px;
    color: var(--bg-brand);
    cursor: pointer;
    /* width: 260px; */
}

.coinPosition {
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
}

.coinSize {
    width: 36px;
    height: auto;
}

.creditTextSize {
    font-size: 18px;
    font-weight: 600;
}

.iconBalanceReloadSize {
    width: 24px !important;
    height: auto !important;
    cursor: pointer;
    color: var(--bg-black);
}

.iconBalanceReloadSize:hover {
    opacity: 0.8;
}

.usernameIconSize {
    width: 36px !important;
    height: 36px !important;
    color: var(--bg-black);
}

.usernameText {
    font-size: 16px;
    font-weight: 600;
    color: var(--bg-black);
}

.navText {
    font-size: 18px;
}

.navbarHeight {
    height: 54px;
}

.condition {
    position: relative;
    border: 1px solid var(--bg-grey);
    color: var(--bg-black);
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.condition:hover {
    background: var(--bg-secondary);
    top: 2px;
}

.cursorBack {
    cursor: pointer;
    font-size: 18px;
}

.cursorBackAlign {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
}

.backIcon {
    width: 28px !important;
    height: 28px !important;
}

@media only screen and (max-width: 600px) {
    .usernameText {
        font-size: 14px;
    }
    .usernameIconSize {
        width: 26px !important;
        height: 26px !important;
    }
    .creditTextSize {
        font-size: 14px;
    }
    .iconBalanceReloadSize {
        width: 20px !important;
        height: 20px !important;
    }
    .condition {
        padding: 6px 14px;
        font-size: 14px;
    }
    .navbarHeight {
        height: 48px;
    }
    .navText {
        font-size: 16px;
    }
    .navbarSubHeigth {
        height: 40px;
    }
    .cursorBack {
        font-size: 14px;
        color: var(--bg-white) !important;
    }
    .backIcon {
        width: 24px !important;
        height: 24px !important;
    }
}

@media only screen and (max-width: 370px) {
    .cursorBack {
        font-size: 12px;
        color: var(--bg-white) !important;
    }
    .usernameText {
        font-size: 12px;
    }
    .usernameIconSize {
        width: 20px !important;
        height: 20px !important;
    }
    .creditTextSize {
        font-size: 12px;
    }
    .iconBalanceReloadSize {
        width: 16px !important;
        height: 16px !important;
    }
    .condition {
        padding: 6px 10px;
        font-size: 12px;
    }
    .navbarHeight {
        height: 48px;
    }
    .navText {
        font-size: 14px;
    }
    .navbarSubHeigth {
        height: 40px;
    }
    .backIcon {
        width: 20px !important;
        height: 20px !important;
    }
}
/* :root { */
    /* --bg-card:#1E2330; 
    --bg-foter:#07090F; 
    --bg-main:#FFFFFF; 
    --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
    --bg-navbar:#0D0F14; 
    --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
    --bg-text-animation:#1E2330; 
    --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
    --bt-danger:#FF0000; 
    --bt-defult:#FFFFFF; 
    --bt-info:#434958; 
    --bt-login:#FFDE8B; 
    --bt-register:#2B00D6; 
    --bt-secondary:#C9C9C9; 
    --bt-sucess:#00ED09; 
    --bt-undefult:#000000; 
    --bt-warning:#FFE000;
    --bg-grey:#2F3642; 
    --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
    --bg-blue:#0038FF; 
    --bg-sea​​blue:#00A3FF;
    --bg-green:#148C00; */

    /* --bg-brand: #D30101;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-line: #C3C4C6;
    --bg-sucess: #00B907;
    --bg-floor: #D5D5D5;
    --bg-warning: #FFE600;
    --bg-card: #FFFFFF;
    --bg-secondary: #E6E8EB;
    --bg-dark-gray: #707070;
    --bg-blue: #001585;
    --bg-grey: #1E2330;
    --bg-danger: #E10000;
}  */

.inputStyle {
    font-size: 19px;
    color: var(--bg-black);
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background: unset;
}

.boxDate {
    background: var(--bg-white);
    padding: 12px 20px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
}

.boxDate input[type=text] {
    text-align: right !important;
}

.boxList {
    font-size: 18px;
}

.listReward {
    background: var(--bg-card);
    border-radius: 10px;
    border: 1px solid var(--bg-line);
}

.rewardTitle {
    background: var(--bg-black);
    color: var(--bg-white);
    padding: 12px;
    border-radius: 10px 10px 0px 0px;
}

.rewardContent {
    font-size: 28px;
    padding: 20px;
}

.rewardNone {
    font-size: 22px;
    /* font-weight: 600; */
}

.selectCode {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    padding: 4px;
    background: transparent;
    border: unset;
    color: var(--bg-black);
    cursor: pointer;
}

.selectCode option {
    color: var(--bg-black);
}

.lotteryFlag {
    width: 30px;
    padding-right: 10px;
}

.cardHead {
    border-radius: 10px 10px 0px 0px;
    background: var(--bg-grey);
}

.cardTitle {
    color: var(--bg-white);
    padding: 15px 20px;
    font-weight: bold;
}

.dateChip {
    background: var(--bg-white);
    font-weight: bold; 
    font-size: 14px;
    border-radius: 15px; 
    color: var(--bg-black);
    padding: 3px 15px;
}

.cardBody {
    background: var(--bg-card);
    border-radius: 0px 0px 10px 10px;
}

.cardTable {
    min-width: 100%;
    border-radius: 0px 0px 10px 10px;
}

.cardTableHead {
    font-weight: bold;
    color: var(--bg-brand);
    font-size: 15px;
}

.cardTableCell {
    font-weight: bold;
    font-size: 14px;
}

@media only screen and (max-width: 600px) {
    .rewardNone {
        font-size: 18px;
    }
    .boxDate {
        font-size: 16px;
        padding: 12px 16px;
    }
    .inputStyle {
        font-size: 17px;
    }
    .selectCode {
        font-size: 16px;
    }
    .rewardContent {
        font-size: 18px;
        padding: 16px 6px;
    }
    .rewardTitle {
        font-size: 14px;
        padding: 8px 2px;
    }
    .boxList {
        font-size: 16px;
    }
}
/* :root { */
  /* --bg-card:#1E2330; 
  --bg-foter:#07090F; 
  --bg-main:#FFFFFF; 
  --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
  --bg-navbar:#0D0F14; 
  --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
  --bg-text-animation:#1E2330; 
  --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
  --bt-danger:#FF0000; 
  --bt-defult:#FFFFFF; 
  --bt-info:#434958; 
  --bt-login:#FFDE8B; 
  --bt-register:#2B00D6; 
  --bt-secondary:#C9C9C9; 
  --bt-sucess:#00ED09; 
  --bt-undefult:#000000; 
  --bt-warning:#FFE000;
  --bg-grey:#2F3642; 
  --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
  --bg-blue:#0038FF; 
  --bg-sea​​blue:#00A3FF;
  --bg-green:#148C00; */

  /* --bg-brand: #D30101;
  --bg-black: #000000;
  --bg-white: #FFFFFF;
  --bg-line: #C3C4C6;
  --bg-sucess: #00B907;
  --bg-floor: #D5D5D5;
  --bg-warning: #FFE600;
  --bg-card: #FFFFFF;
  --bg-secondary: #E6E8EB;
  --bg-dark-gray: #707070;
  --bg-blue: #001585;
  --bg-grey: #1E2330;
  --bg-danger: #E10000;

}  */

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.relative {
  position: relative;
}

.wrapper {
  margin-top: 20px;
}

.boxMarginTopHight {
  margin-top: 40px;
}

.textCLSuccess {
  color: var(--bg-sucess);
}

.textCLRegister {
  color: var(--bg-blue);
}

.textCLUndefult {
  color: var(--bg-black);
}

.textCLDanger {
  color: var(--bg-danger);
}

.textCLDefult {
  color: var(--bg-black);
}

.textCLWarning {
  color: var(--bg-warning);
}

.textCLBrand {
  color: var(--bg-brand);
}

.textBoldLow {
  font-weight: 400;
}

.textBoldMedium {
  font-weight: 500;
}

.textBoldHight {
  font-weight: bold;
}

.alertTitle {
  font-size: 18px;
}

.alertText {
  font-size: 16px;
}

.label {
  font-size: 13px;
  padding: 3px 12px;
  border-radius: 50px;
  margin-left: 6px;
}

.labelFontSize {
  font-size: 14px;
  font-weight: bold;
}

.labelInfo {
  background: var(--bg-dark-gray);
  color: var(--bg-white);
}

.labelSuccess {
  background: var(--bg-sucess);
  color: var(--bg-white);
}

.labelDanger {
  background: var(--bg-danger);
  color: var(--bg-white);
}

.labelWarning {
  background: var(--bg-warning);
  color: var(--bg-black);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* SVG Animate */
.svg-box {
  display: inline-block;
  position: relative;
  width: 150px;
  transform: scale(1.3, 1.3);
  margin-top: 20px;
  margin-bottom: 10px;
}

.green-stroke {
  stroke:#7CB342;
}

.red-stroke {
  stroke: #FF6245;
}

.yellow-stroke {
  stroke: #FFC107;
}

.circular {
  /* stroke-width: 300px; */
  /* stroke-: 300px; */
}

.circular circle.path {
  stroke-dasharray: 330;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  opacity: 0.4;
  animation: 0.7s draw-circle ease-out;
}

/*------- Checkmark ---------*/
.checkmark {
  stroke-width: 6.25;
  stroke-linecap: round;
  position:absolute;
  top: 56px;
  left: 49px;
  width: 62px;
  height: 50px;
}

.checkmark path {
  animation: 1s draw-check ease-out;
}

@keyframes draw-circle {
  0% {
      stroke-dasharray: 0,330;
      stroke-dashoffset: 0;
      opacity: 1;
  }

  80% {
      stroke-dasharray: 330,330;
      stroke-dashoffset: 0;
      opacity: 1;
  }

  100%{
      opacity: 0.4;
  }
}

@keyframes draw-check {
  0% {
      stroke-dasharray: 49,80;
      stroke-dashoffset: 48;
      opacity: 0;
  }

  50% {
      stroke-dasharray: 49,80;
      stroke-dashoffset: 48;
      opacity: 1;
  }

  100% {
      stroke-dasharray: 130,80;
      stroke-dashoffset: 48;
  }
}

/*---------- Cross ----------*/

.cross {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 54px;
  left: 54px;
  width: 40px;
  height: 40px;
}

.cross .first-line {
  animation: 0.7s draw-first-line ease-out;
}

.cross .second-line {
  animation: 0.7s draw-second-line ease-out;
}

@keyframes draw-first-line {
  0% {
      stroke-dasharray: 0,56;
      stroke-dashoffset: 0;
  }

  50% {
      stroke-dasharray: 0,56;
      stroke-dashoffset: 0;
  }

  100% {
      stroke-dasharray: 56,330;
      stroke-dashoffset: 0;
  }
}

@keyframes draw-second-line {
  0% {
      stroke-dasharray: 0,55;
      stroke-dashoffset: 1;
  }

  50% {
      stroke-dasharray: 0,55;
      stroke-dashoffset: 1;
  }

  100% {
      stroke-dasharray: 55,0;
      stroke-dashoffset: 70;
  }
}

.alert-sign {
  stroke-width:6.25;
  stroke-linecap: round;
  position: absolute;
  top: 40px;
  left: 68px;
  width: 15px;
  height: 70px;
  animation: 0.5s alert-sign-bounce cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.alert-sign .dot {
  stroke:none;
  fill: #FFC107;
}

@keyframes alert-sign-bounce {
  0% {
      transform: scale(0);
      opacity: 0;
  }

  50% {
      transform: scale(0);
      opacity: 1;
  }

  100% {
      transform: scale(1);
  }
}
/* End SVG Animate */

#modal ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: unset;
}

/* Loadding */
.loadPage {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.816);
  transform: translate(0px, 0px) !important;
  color: white;
}

.loadderCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.textLoadding {
  margin-top: 36px;
  font-size: 20px;
  width: 100%;
  color: #d9d9d9;
}

.loadderAlign {
  height: 70px;
}

.loader {
  width: 70px;
  height: 70px;
  display: inline-block;
  position: relative;
  border-width: 8px 7px 8px 7px;
  border-style: solid dotted solid dotted;
  /* border-color: #de3500 rgba(255, 255, 255,0.3) #fff rgba(151, 107, 93, 0.3); */
  border-color: var(--bg-brand) #fff #fff var(--bg-brand);
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
}

.loader:before , .loader:after{
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: var(--bg-floor);
  transform: translate(-14px, 30px) rotate(-46deg);
}

.loader:after {
  /* border-color: var(--bt-danger) #0000 #0000 #0000 ; */
  border-color: var(--bg-brand) transparent transparent transparent ;
  transform: translate(50px, 5px) rotate(-46deg);
}

@keyframes rotate {
  100% {    
    transform: rotate(360deg)
  }
}

/* End Loadding */

/* Responsive */
@media only screen and (max-width: 600px) {
  .wrapper {
    margin-top: 14px;
  }
  .containerWidth {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .labelFontSize {
    font-size: 12px;
  }
  .label {
    padding: 3px 6px;
  }
}
/* :root { */
  /* --bg-card:#1E2330; 
  --bg-foter:#07090F; 
  --bg-main:#FFFFFF; 
  --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
  --bg-navbar:#0D0F14; 
  --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
  --bg-text-animation:#1E2330; 
  --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
  --bt-danger:#FF0000; 
  --bt-defult:#FFFFFF; 
  --bt-info:#434958; 
  --bt-login:#FFDE8B; 
  --bt-register:#2B00D6; 
  --bt-secondary:#C9C9C9; 
  --bt-sucess:#00ED09; 
  --bt-undefult:#000000; 
  --bt-warning:#FFE000;
  --bg-grey:#2F3642; 
  --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
  --bg-blue:#0038FF; 
  --bg-sea​​blue:#00A3FF;
  --bg-green:#148C00; */

  /* --bg-brand: #D30101;
  --bg-black: #000000;
  --bg-white: #FFFFFF;
  --bg-line: #C3C4C6;
  --bg-sucess: #00B907;
  --bg-floor: #D5D5D5;
  --bg-warning: #FFE600;
  --bg-card: #FFFFFF;
  --bg-secondary: #E6E8EB;
  --bg-dark-gray: #707070;
  --bg-blue: #001585;
  --bg-grey: #1E2330;
  --bg-danger: #E10000;
}  */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Sarabun', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-floor);
  color: var(--bg-black);
  width: 100%;
  height: 100%;
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
}

.Sarabun {
  font-family: 'Sarabun', sans-serif;
}

hr {
  border-color: var(--bt-info);
}
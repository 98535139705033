/* :root { */
  /* --bg-card:#1E2330; 
  --bg-foter:#07090F; 
  --bg-main:#FFFFFF; 
  --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
  --bg-navbar:#0D0F14; 
  --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
  --bg-text-animation:#1E2330; 
  --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
  --bt-danger:#FF0000; 
  --bt-defult:#FFFFFF; 
  --bt-info:#434958; 
  --bt-login:#FFDE8B; 
  --bt-register:#2B00D6; 
  --bt-secondary:#C9C9C9; 
  --bt-sucess:#00ED09; 
  --bt-undefult:#000000; 
  --bt-warning:#FFE000;
  --bg-grey:#2F3642; 
  --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
  --bg-blue:#0038FF; 
  --bg-sea​​blue:#00A3FF;
  --bg-green:#148C00; */

  /* --bg-brand: #D30101;
  --bg-black: #000000;
  --bg-white: #FFFFFF;
  --bg-line: #C3C4C6;
  --bg-sucess: #00B907;
  --bg-floor: #D5D5D5;
  --bg-warning: #FFE600;
  --bg-card: #FFFFFF;
  --bg-secondary: #E6E8EB;
  --bg-dark-gray: #707070;
  --bg-blue: #001585;
  --bg-grey: #1E2330;
  --bg-danger: #E10000;
} */

.boxMarginTop {
  margin-top: 30px;
}

.boxMediumMarginTop {
  margin-top: 20px;
}

.boxMediumMarginBottom {
  margin-bottom: 20px;
}

.boxPaddingBottom {
  padding-bottom: 160px;
}

.boxContent {
  background: var(--bg-card);
  padding: 20px;
  color: var(--bg-black) !important;
  font-size: 18px;
  margin-top: 5px;
}

.boxContentRadius {
  background: var(--bg-card);
  padding: 20px;
  border-radius: 10px;
  color: var(--bg-black);
  font-size: 18px;
  margin-top: 5px;
}

.footerPosition {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.root {
  opacity: 1 !important;
  color: var(--bg-black) !important;
}

.tabText {
  font-size: 18px !important;
  color: var(--bg-dark-gray) !important;
  padding-bottom: 24px !important;
}

.tabText:hover {
  opacity: 0.8;
}

.tabTextActive {
  opacity: 1 !important;
  color: var(--bg-brand) !important;
  font-size: 18px !important;
  padding-bottom: 24px !important;
}

.indicator {
  background-color: var(--bg-brand) !important;
}

.tabContent {
  padding: 15px 0px;
}

.tabIconSize {
  width: 60px !important;
  height: 100% !important;
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 600px) {
  .boxContent {
    border-radius: 0px;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 8px;
  }
  .boxContentRadius {
    padding: 10px;
  }
  .boxMarginTop {
    margin-top: 16px;
  }
  .tabText,
  .tabTextActive {
    font-size: 16px !important;
  }
  .tabIconSize {
    width: 40px !important;
    height: 100% !important;
  }
  .boxMediumMarginTop {
    margin-top: 10px;
  }
  .boxPaddingBottom {
    padding-bottom: 130px;
  }
}
/* :root { */
    /* --bg-card:#1E2330; 
    --bg-foter:#07090F; 
    --bg-main:#FFFFFF; 
    --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
    --bg-navbar:#0D0F14; 
    --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
    --bg-text-animation:#1E2330; 
    --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
    --bt-danger:#FF0000; 
    --bt-defult:#FFFFFF; 
    --bt-info:#434958; 
    --bt-login:#FFDE8B; 
    --bt-register:#2B00D6; 
    --bt-secondary:#C9C9C9; 
    --bt-sucess:#00ED09; 
    --bt-undefult:#000000; 
    --bt-warning:#FFE000;
    --bg-grey:#2F3642; 
    --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
    --bg-blue:#0038FF; 
    --bg-sea​​blue:#00A3FF;
    --bg-green:#148C00; */

    /* --bg-brand: #D30101;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-line: #C3C4C6;
    --bg-sucess: #00B907;
    --bg-floor: #D5D5D5;
    --bg-warning: #FFE600;
    --bg-card: #FFFFFF;
    --bg-secondary: #E6E8EB;
    --bg-dark-gray: #707070;
    --bg-blue: #001585;
    --bg-grey: #1E2330;
    --bg-danger: #E10000;
}  */

.keyboard {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.keyboard .letter {
    /* float: left; */
    display: relative;
    margin: 0 5px 5px 0;
    width: 60px;
    height: 60px;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    background: var(--bg-white);
    border: 1px solid var(--bg-line);
    border-radius: 5px;
    flex: 0 0 31.333333%;
    color: var(--bg-black);
}

.keyboard .letterDelete {
    /* float: left; */
    display: relative;
    margin: 0 5px 5px 0;
    width: 60px;
    height: 60px;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    background: var(--bg-brand);
    border: 1px solid var(--bg-brand);
    border-radius: 5px;
    flex: 0 0 31.333333%;
    color: var(--bg-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.keyboard .letterCancel {
    /* float: left; */
    display: relative;
    margin: 0 5px 5px 0;
    width: 60px;
    height: 60px;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    background: var(--bg-grey);
    border: 1px solid var(--bg-grey);
    border-radius: 5px;
    flex: 0 0 31.333333%;
    color: var(--bg-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.capslock,
.tab,
.left-shift,
.clearl,
.switch {
    clear: left;
}

.keyboard .tab,
.keyboard .delete {
    width: 70px;
}

.keyboard .capslock {
    width: 80px;
}

.keyboard .return {
    width: 90px;
}

.keyboard .left-shift {
    width: 70px;
}

.keyboard .switch {
    width: 90px;
}

.keyboard .rightright-shift {
    width: 109px;
}

.keyboard .space {
    float: left;
    width: 556px;
}

.keyboard .switch,
.keyboard .space,
.keyboard .return {
    font-size: 16px;
}

.keyboard div:hover {
    position: relative;
    top: 1px;
    left: 1px;
    /* border-color: #e5e5e5; */
    cursor: pointer;
    /* border-color: #24c9b6;
    box-shadow: 0 0 5px #24c9b6 inset; */
}

.boxNumber {
    background: var(--bg-white);
    border: 1px solid var(--bg-dark-gray);
    width: 74px;
    height: 74px;
    line-height: 74px;
    border-radius: 10px;
    font-size: 40px;
    font-weight: 500;
    color: var(--bg-black);
}

.boxNumberBorder {
    background: var(--bg-black);
    width: 2px;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: caret 1.5s infinite;
}

.iconDeleteSize {
    width: 36px !important;
    height: 100% !important;
}

.boxTimeout {
    font-size: 18px;
}

.borderTimeout {
    font-size: 18px;
    font-weight: 600;
    padding: 8px;
    border-radius: 10px;
    border: 2px solid var(--bg-brand); 
    color: var(--bg-black);
    background: unset;
}

.borderTimeoutSize {
    width: 100%;
}

.iconTimeOutSize {
    width: 32px;
    height: 100%;
}

.boxCondition {
    font-size: 18px;
}

.conditionTitle {
    font-size: 20px;
    padding: 16px 15px;
    border-bottom: 2px solid var(--bg-line);
}

.shootListTitle {
    font-size: 20px;
    padding: 16px 20px;
    border-bottom: 2px solid var(--bg-line);
    text-align: center;
}

.shootListFirst {
    color: var(--bg-brand);
    font-weight: bold;
}

.shootListOrder {
    color: var(--bg-blue);
    font-weight: bold;
}

.boxFooter {
    position: relative;
    font-size: 18px;
    background: var(--bg-grey);
    padding: 14px;
    color: var(--bg-white);
    border-radius: 10px;
    cursor: pointer;
}

.boxFooter:hover {
    top: 2px;
}

.footerNumber {
    font-size: 24px;
}

.boxTypeBorder {
    /* padding: 12px 0px; */
    /* border-top: 2px solid var(--bt-info); */
    margin: 16px 0px;
}

.typeIconAlign {
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
}

.typeIconSize {
    width: 22px !important;
    height: 22px !important;
}

.conditionList {
    padding: 16px 20px;
    font-size: 18px;
}

.conditionListBorder {
    padding: 16px 20px;
    font-size: 18px;
    border-bottom: 1px solid var(--bt-info);
}

.listText {
    display: inline-block;
    font-size: 16px;
}

.listMarginTop {
    margin-top: 10px;
}

.tableListPopup td {
    font-size: 16px !important;
}

.shootRewardTitle {
    font-size: 20px;
    font-weight: 700;
    padding: 10px 20px;
    text-align: center;
    color: var(--bg-black);
}

.shootRewardHead {
    font-size: 17px;
    font-weight: 700;
    text-align: center;
}

.shootRewardData {
    font-size: 24px;
    padding: 5px;
    text-align: center;
    color: var(--bg-brand);
    font-weight: bold;
}

.shootRewardUser {
    font-size: 20px;
    font-weight: 700;
    padding: 5px;
    text-align: center;
    color: var(--bg-brand);
}

.shootPushNumber {
    color: var(--bg-black);
    font-size: 30px;
}

.shootWait {
    color: var(--bg-brand);
}

.cardContentMarginTop {
    margin-top: 10px;
}

.tableBorderBottom {
    border-bottom: solid 0.5px var(--bg-line);
}

.tableBorderTop {
    border-top: solid 1px var(--bg-line);
}

.tableDataHead {
    color: var(--bg-dark-gray);
    font-weight: 600;
}

.textLoadding {
    margin-top: 25px;
    font-size: 18px;
    width: 100%;
}

@keyframes caret {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .boxTimeout {
        font-size: 14px;
    }
    .typeIconSize {
        width: 18px !important;
        height: 18px !important;
    }
    .conditionTitle {
        font-size: 16px;
        padding: 14px 10px;
    }
    .conditionListBorder {
        font-size: 16px;
    }
    .listText {
        font-size: 14px;
    }
    .boxFooter {
        font-size: 16px;
        padding: 8px 12px;
    }
    .borderTimeout {
        border: 1px solid var(--bg-brand);
    }
    .borderTimeout {
        font-size: 16px;
    }
    .letter {
        font-size: 22px !important;
        flex: 0 0 30% !important;
    }
    .letterCancel {
        font-size: 16px !important;
    }
    .iconDeleteSize {
        width: 26px !important;
    }
    .boxNumber {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 30px;
    }
    .tableListPopup td {
        font-size: 14px !important;
    }
    .listTdPaddingPopup {
        padding-left: 4px !important;
    }
    .shootRewardTitle {
        font-size: 17px;
        font-weight: 700;
        padding: 10px 20px;
        text-align: center;
        color: var(--bg-black);
    }
    .shootRewardHead {
        font-size: 14px;
        text-align: center;
    }
    .shootRewardData {
        font-size: 20px;
        font-weight: 700;
        padding: 5px;
        text-align: center;
        color: var(--bg-danger);
    }
    .shootRewardUser {
        font-size: 16px;
        font-weight: 700;
        padding: 5px;
        text-align: center;
        color: var(--bg-danger);
    }
    .tableListReward td {
        font-size: 14px !important;
        border-bottom: solid 1px #fff;
    }
    .tableBorderBottom {
        border-bottom: solid 0.5px var(--bg-line);
    }
    .tableFont td {
        font-size: 13px !important;
    }
    .textLoadding {
        font-size: 18px;
        width: 100%;
    }
    .iconTimeOutSize {
        width: 24px;
    }
}

@media only screen and (max-width: 370px) {
    .boxTimeout {
        font-size: 14px;
    }
    .typeIconSize {
        width: 18px !important;
        height: 18px !important;
    }
    .conditionListBorder {
        font-size: 16px;
    }
    .listText {
        font-size: 14px;
    }
    .boxFooter {
        font-size: 16px;
        padding: 12px;
    }
    .borderTimeout {
        border: 1px solid var(--bt-info);
    }
    .borderTimeout {
        font-size: 16px;
    }
    .letter {
        font-size: 18px !important;
    }
    .letterCancel {
        font-size: 14px !important;
    }
    .shootRewardTitle {
        font-size: 17px;
        padding: 10px 20px;
        text-align: center;
        color: var(--bg-black);
    }
    .shootRewardHead {
        font-size: 14px;
        text-align: center;
    }
    .shootRewardData {
        font-size: 20px;
        padding: 5px;
        text-align: center;
        color: var(--bg-danger);
    }
    .shootRewardUser {
        font-size: 16px;
        padding: 5px;
        text-align: center;
        color: var(--bg-danger);
    }
    .tableBorderBottom {
        border-bottom: solid 0.5px var(--bt-info);
    }
}
/* :root { */
    /* --bg-card:#1E2330;  */
    /* --bg-foter:#07090F;  */
    /* --bg-main:#FFFFFF;  */
    /* --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%);  */
    /* --bg-navbar:#0D0F14;  */
    /* --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%);  */
    /* --bg-text-animation:#1E2330;  */
    /* --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%);  */
    /* --bt-danger:#FF0000;  */
    /* --bt-defult:#FFFFFF;  */
    /* --bt-info:#434958;  */
    /* --bt-login:#FFDE8B;  */
    /* --bt-register:#2B00D6;  */
    /* --bt-secondary:#C9C9C9;  */
    /* --bt-sucess:#00ED09;  */
    /* --bt-undefult:#000000;  */
    /* --bt-warning:#FFE000; */
    /* --bg-grey:#2F3642;  */
    /* --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%);  */
    /* --bg-blue:#0038FF;  */
    /* --bg-sea​​blue:#00A3FF; */
    /* --bg-green:#148C00; */

    /* --bg-brand: #D30101;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-line: #C3C4C6;
    --bg-sucess: #00B907;
    --bg-floor: #D5D5D5;
    --bg-warning: #FFE600;
    --bg-card: #FFFFFF;
    --bg-secondary: #E6E8EB;
    --bg-dark-gray: #707070;
    --bg-blue: #001585;
    --bg-grey: #1E2330;
    --bg-danger: #E10000; */
  /* }  */
  

.boxMenu {
    position: relative;
    background: var(--bg-card);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 24px 4px;
    cursor: pointer;
}

.boxMenu:hover {
    top: 2px;
}

.menuImg {
    width: 80px;
    height: 100%;
}

.menuMargin {
    margin-top: 8px;
}

.menuText {
    font-size: 18px;
    font-weight: 500;
    color: var(--bg-black);
}

.menuIcon {
    width: 68px !important;
    height: 68px !important;
    color: var(--bg-brand) !important;
}

/* .listTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--bt-login);
} */

.lottoCard {
    border-radius: 12px;
    border: 1px solid var(--bg-line);
    background: var(--bg-card);
}

.lottoCardActive {
    cursor: pointer;
    position: relative;
}

.lottoCardActive:hover {
    top: 2px;
}

/* .lottoCardNotActive {
    
} */

.lottoImg {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0px 0px;
}

.lottoCardContent {
    padding: 12px;
}

.lottoCardImagePosition {
    position: absolute;
    top: 50%;
    left: 4%;
    padding: 8px;
    transform: translateY(-50%);
}

.lottoCountryImg {
    width: 52px;
    height: 100%;
    border-radius: 50px;
}

.lottoCardTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--bg-white);
}

.lottoCardText {
    font-size: 18px;
    color: var(--bg-warning);
}

.lottoryTitle {
    font-size: 20px;
    color: var(--bg-black);
}

@media only screen and (max-width: 600px) {
    .menuIcon {
        width: 40px !important;
        height: 40px !important;
    }
    .menuText {
        font-size: 16px;
    }
    .boxMenu {
        padding: 14px 4px;
    }
    .lottoCardTitle {
        font-size: 14px;
        color: var(--bg-white);
    }
    .lottoCountryImg {
        width: 30px;
    }
    .lottoCardText {
        font-size: 12px;
    }
    .lottoCardImagePosition {
        padding: 2px;
    }
    .btnFontSize {
        font-size: 14px;
    }
}

@media only screen and (max-width: 370px) {
    .lottoCardImagePosition {
        padding: 0px;
    }
    .lottoCountryImg {
        width: 26px;
    }
    .menuMargin {
        margin-top: 4px;
    }
    .lottoCardTitle {
        font-size: 12px;
        color: var(--bg-white);
    }
    .lottoCardText {
        font-size: 10px;
    }
    .menuText {
        font-size: 14px; 
    }
    .boxMenu {
        padding: 10px 4px;
    }
    .menuIcon {
        width: 35px !important;
        height: 35px !important;
    }
    .lottoryTitle {
        font-size: 16px;
    }
}
/* :root { */
    /* --bg-card:#1E2330; 
    --bg-foter:#07090F; 
    --bg-main:#FFFFFF; 
    --bg-modal:linear-gradient(180deg, #1E2330 0%, #000614 100%); 
    --bg-navbar:#0D0F14; 
    --bg-popup:linear-gradient(180deg, #EC0000 0%, #9C0000 100%); 
    --bg-text-animation:#1E2330; 
    --bt-confirm:linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%); 
    --bt-danger:#FF0000; 
    --bt-defult:#FFFFFF; 
    --bt-info:#434958; 
    --bt-login:#FFDE8B; 
    --bt-register:#2B00D6; 
    --bt-secondary:#C9C9C9; 
    --bt-sucess:#00ED09; 
    --bt-undefult:#000000; 
    --bt-warning:#FFE000;
    --bg-grey:#2F3642; 
    --bg-gold:linear-gradient(180deg, #FFD879 0%, #E8AA16 100%); 
    --bg-blue:#0038FF; 
    --bg-sea​​blue:#00A3FF;
    --bg-green:#148C00; */
/* 
    --bg-brand: #D30101;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-line: #C3C4C6;
    --bg-sucess: #00B907;
    --bg-floor: #D5D5D5;
    --bg-warning: #FFE600;
    --bg-card: #FFFFFF;
    --bg-secondary: #E6E8EB;
    --bg-dark-gray: #707070;
    --bg-blue: #001585;
    --bg-grey: #1E2330;
    --bg-danger: #E10000;
}  */

.navbarPosition {
    background: var(--bg-grey);
    color: var(--bg-white);
    font-size: 18px;
    /* border-radius: 10px; */
}

.navbarCloseIcon {
    position: relative;
    width: 42px !important;
    height: 100% !important;
    cursor: pointer;
}

.navbarCloseIcon:hover {
    top: 2px;
}

.navbarNumber {
    font-size: 22px;
    margin: 0px 8px;
}

.boxList,
.boxInputAmount {
    font-size: 18px;
    color: var(--bg-white);
}

.listHeight {
    /* min-height: 400px; */
}

.listMarginBottom {
    margin-bottom: 16px;
}

.listTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    color: var(--bg-black);
}

.listElement {
    background: var(--bg-white);
    color: var(--bg-black);
    border-radius: 10px;
    /* height: 51px; */
}

.elementPaddingFirst {
    padding: 14px;
}

.elementPadding {
    padding: 0px 14px;
}

.elementLottoryNumber {
    background: var(--bg-brand);
    border-radius: 8px 0px 0px 8px;
    font-size: 21px;
    color: var(--bg-white);
}

.elementBorderRight {
    border-right: 1px solid #BABDC8;
}

.elementResult {
    color: var(--bg-sucess);
    font-weight: 700;
    font-size: 16px;
}

.elementRatePay {
    background: var(--bg-grey);
    color: var(--bg-white);
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    text-align: center;
}

.listDelete {
    position: relative;
    background: var(--bg-white);
    height: 56px;
    border-radius: 10px;
    cursor: pointer;
}

.listDelete:hover {
    top: 2px;
}

.listDeleteIcon {
    width: 34px !important;
    height: 100% !important;
    color: var(--bg-danger);
}

.inputAmountPadding {
    padding: 20px;
}

.moneySelect {
    position: relative;
    border: 1px solid var(--bg-grey);
    padding: 12px 0px;
    margin: 0px 8px;
    width: 90px;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    color: var(--bg-black);
    font-weight: bold;
}

.moneySelect:hover {
    top: 2px;
    background: var(--bg-secondary);
}

.resultCredit {
    padding: 20px;
    background: var(--bg-line);
    border-radius: 0px 0px 0px 10px;
    color: var(--bg-black);
    font-weight: bold;
}

.resultAmount {
    padding: 20px;
    background: var(--bg-secondary);
    border-radius: 0px 0px 10px 0px;
    color: var(--bg-black);
    font-weight: bold;
}

.resultNumber {
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
    color: var(--bg-brand);
}

.lottoryMarginBottom {
    margin-bottom: 8px;
}

.duplicateIconAlign {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
}

.duplicateIconSize {
    width: 22px !important;
    height: 100% !important;
}

.warningBorder {
    border: 2px solid var(--bg-danger);
}

@media only screen and (max-width: 600px) {
    .btnOutLineColor {
        font-size: 14px;
        padding: 12px 4px;
        border: 1px solid var(--bg-line);
    }
    .btn {
        font-size: 14px;
    }
    .navbarPosition {
        font-size: 16px;
    }
    .inputAmountPadding {
        padding: 10px;
    }
    .listTitle {
        font-size: 16px;
        font-weight: bold;
    }
    .moneySelect {
        font-size: 14px;
        width: 60px;
        margin: 0px 2px;
        padding: 10px 0px;
    }
    .resultCredit,
    .resultAmount {
        font-size: 16px;
        padding: 14px 10px;
    }
    .resultNumber {
        font-size: 18px;
    }
    .elementLottoryNumber {
        font-size: 15px;
        font-weight: bold;
    }
    .elementResult {
        font-size: 12px;
    }
    .elementRatePay {
        padding: 5px;
        font-size: 10px;
    }
    .elementPaddingFirst {
        padding: 12px 5px;
    }
    .inputStyles {
        font-size: 15px !important;
    }
    /* .listElement {
        border-radius: 6px;
    } */
    .listDeleteIcon {
        width: 28px !important;
        height: 100% !important;
    }
    .listDelete {
        height: 48px;
    }
    .elementPaddingFirstMobile {
        padding: 14px 10px;
    }
    .elementPadding {
        padding: 0px 4px;
    }
    .navbarNumber {
        font-size: 18px;
    }
    .navbarFull {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
    }
    .navbarCloseIcon {
        width: 36px !important;
    }
}

@media only screen and (max-width: 370px) {
    .moneySelect {
        width: 50px;
    }
    .keyboard,
    .letter {
        flex: 0 0 31%;
    }
}
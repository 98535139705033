
/* Reload Balance */
.refresh {
    margin: auto;
}

.spin {
    margin: auto;
    animation: spin 1s infinite;
}

.refresh {
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* End Reload Balance */